@import "~styles/utils/media-queries";

$product_width_desktop: 275px;
$product_width_tablet: 25%;
$product_width_mobile: 100%;
$product_width_desktop_slim: 15.6667%;
$product_width_tablet_slim: 32.32%;
$product_height: 100%;
$product_height_slim: 100%;

.container + .productlist {
  @media (--mobile) {
    margin: 0 -10px !important;
  }
}

.productlist {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  float: none;
  &--view-more {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    grid-column: 1 / -1;
    @media (--mobile) {
      margin-top: 5px;
    }
  }
  &--no-results {
    grid-column: 1 / -1;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
    color: grey;
    font-style: normal;
    flex-direction: column;
  }
  &__productlist-end {
    flex-grow: 1;
    width: 24%;
    min-height: 220px;
    color: #999;
    fill: #999;
    flex-direction: column;
    margin: 0 5px 10px 5px;
    border: 1px solid #dedede;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.4s ease;
    &-arrow {
      transform: rotate(-90deg);
      height: 25px;
      margin-bottom: 13px;
    }

    &:hover {
      color: #444;
      fill: #444;
      box-shadow: 0 2px 10px -2px #bbb;
    }
  }
  &__campaign {
    background-color: var(--color-brand-brighter-1);
    border: none;
    padding: 40px 0 60px 0;
    margin-top: -40px;
    margin-bottom: -60px;
    @media (--mobile) {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .productbox__slider {
      background-color: var(--white);
    }
    .design-vvskupp & {
      background-color: var(--highligted-background-color);
      color: var(--color-on-highligted-background-color);
      .new-productbox {
        border: var(--campaign-color);
      }
      .productbox__slider {
        background-color: color-mix(in srgb, var(--campaign-color), #fff 60%);
        &__indicator {
          background-color: color-mix(in srgb, var(--campaign-color), #000 20%);
          &__wrapper {
            &:hover,
            &--active {
              .productbox__slider__indicator {
                box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2);
                @media (--mobile) {
                  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
                }
              }
            }
          }
        }
      }
    }

    .design-lavpris &,
    .design-greenline & {

      .productbox__list__nav__wrapper .none-active {
        border-color: var(--color-primary-300);
        svg {
          fill: var(--color-primary-300);
        }

      }
    }
    .design-greenline & {
      .product-card__content__toggle {
        fill: var(--white);
      }
      .productbox__slider {
        &__indicator {
          &__wrapper {
            &:hover,
            &--active {
              .productbox__slider__indicator {
                box-shadow: 0 0 0 10px rgba(42, 161, 114, 0.4);
                @media (--mobile) {
                  box-shadow: 0 0 0 6px rgba(42, 161, 114, 0.4);
                }
              }
            }
          }
        }
      }
      .productbox__list__nav {
        border-color: var(--offset-of-brand-color-darker);
        svg {
          fill: var(--offset-of-brand-color-darker);
        }
        &:hover {
          background-color: var(--offset-of-brand-color-darker);
          svg {
            fill: var(--white);
          }
        }
      }
      .product-card__content__header__h2 {
        color: var(--white);
      }
      .product-card__content__header__h3 {
        color: var(--offset-of-brand-color-lighter);
      }
    }
    .design-lampeguru & {
      .product-card__content__header__h2,
      .product-card__content__header__h3 {
        color: var(--white);
      }
    }

    .productlist__grid {
      @media (--mobile) {
        padding: 0 15px;
        margin: 0;
      }
    }

    @media (--tablet-sm) {
      padding: 30px 0 50px 0;
      margin-top: -30px;
      margin-bottom: -50px;
    }
    @media (--mobile) {
      margin: -15px 0;
      padding: 0 0 15px 0;
    }
    &-header {
      padding: 0;
      text-transform: var(--title-uppercase);
      max-width: 852px;

      width: calc(100% - 290px);
      .design-lavpris &,
      .design-completvvs & {
        text-align: center;
        margin: auto;
      }
      &.img {
        width: 100%;
      }
      @media (--tablet-lg) {
        .shop-lavprisel-dk & {
          width: calc(100% - 500px);
        }
        .shop-lavprisvaerktoej-dk & {
          width: calc(100% - 460px);
        }
      }
      @media (--tablet-sm-and-less) {
        max-width: 100%;
        width: 100%;
      }
      @media (--mobile) {
        text-align: left;
        width: 100%;

        margin-left: 0;
        margin-right: 0;

        .design-lavpris & {
          text-align: center;
        }
      }

      .heading--1 {
        letter-spacing: var(--letter-spacing);
        word-spacing: var(--word-spacing);
        @media (--tablet) {
          letter-spacing: var(--letter-spacing-sm);
          word-spacing: var(--word-spacing-sm);
        }
        @media (--mobile) {
          letter-spacing: var(--letter-spacing-xs);
          word-spacing: var(--word-spacing-xs);
          word-break: break-word;
        }
      }
    }

    &-text {
      padding: 0;
      max-width: 852px;

      width: calc(100% - 290px);
      .design-lavpris &,
      .design-completvvs & {
        text-align: center;
        margin: auto;
      }
      &.img {
        width: 100%;
      }
      @media (--tablet-lg) {
        .shop-lavprisel-dk & {
          width: calc(100% - 500px);
        }
        .shop-lavprisvaerktoej-dk & {
          width: calc(100% - 460px);
        }
      }
      @media (--tablet-sm-and-less) {
        max-width: 100%;
        width: 100%;
      }
      @media (--mobile) {
        text-align: left;
        width: 100%;

        margin-left: 0;
        margin-right: 0;

        .design-lavpris & {
          text-align: center;
        }
      }

      &__countdown {
        p {
          background-color: var(--color-urgent);
          color: var(--white);
          width: fit-content;
          padding: 5px 15px 5px 15px;
          text-transform: var(--text-uppercase-on-splash-notification);
          font-size: 13px;
          line-height: normal;
          border-radius: var(--rounded-corners-sm);

          .design-lavpris &,
          .design-completvvs & {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }

          @media (--mobile) {
            margin-left: 0;
            margin-right: 0;
            text-align: left;
            font-size: 12px;

            .design-billigvvs &,
            .design-lampeguru &,
            .design-greenline & {
              padding: 3px 15px;
            }
            .design-lavpris & {
              margin-left: auto;
              margin-right: auto;
              text-align: center;
            }
          }
        }
      }
      &__expire {
        opacity: 0.8;
        .design-lavpris &,
        .design-completvvs & {
          text-align: center;
        }
        p {
          font-size: smaller;
          margin: 0;
          @media (--mobile) {
            text-align: left;
            .design-lavpris & {
              text-align: center;
            }
          }
        }
      }
    }
    &__splash {
      &--text {
        left: 15px;
        top: 15px;
        position: absolute;
        @media (--tablet-lg) {
          left: 55px;
        }
        @media (--tablet-sm) {
          top: 10px;
          left: 40px;
        }
        @media (--mobile) {
          top: 10px;
          left: 10px;
        }
      }
      &--pct {
        top: 15px;
        left: 15px;
        height: 20% !important;
        width: 20% !important;
        justify-content: flex-start !important;

        @media (--tablet-lg) {
          left: 55px;
        }
        @media (--tablet-sm-and-less) {
          top: 10px;
          left: 40px;
          height: 18% !important;
          width: 18% !important;
        }
        @media (--mobile) {
          left: 10px;
        }
      }
      &__no-img {
        &--text {
          left: 15px;
          top: 15px;
          position: absolute;
          @media (--tablet-lg) {
            left: 55px;
          }
          @media (--tablet-sm) {
            top: 10px;
            left: 40px;
          }
          @media (--mobile) {
            top: 10px;
            left: 10px;
            margin-bottom: 10px;
            position: unset !important;
          }
        }
        &--pct {
          top: 0;
          left: 10px;
          height: 90% !important;
          width: 90% !important;
          justify-content: flex-start !important;

          @media (--tablet-lg) {
            left: 55px;
          }
          @media (--tablet-sm-and-less) {
            top: 10px;
            left: 40px;
          }
          @media (--mobile) {
            left: 10px;
            position: unset !important;
            margin-bottom: 10px;
          }
        }
      }
    }
    &-description {
      padding: 0 0 15px 0;
      min-height: 230px;
      @media (--tablet-sm) {
        padding: 0;
        min-height: 200px;
      }
      @media (--mobile) {
        min-height: auto;
        padding: 15px 15px 0 15px;
      }
      .container {
        @media (--tablet-lg) {
          padding-left: 40px;
          padding-right: 40px;
        }
        @media (--tablet-sm) {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
      .half-width {
        &__wrapper {
          background-color: transparent;
          padding: 0;
          margin-left: 0;
          margin-right: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .design-lavpris &,
          .design-completvvs & {
            text-align: center;
          }
          @media (--tablet-xs-and-less) {
            flex-direction: column;
            margin-left: 0;
            margin-right: 0;
          }
        }
        &__image {
          border-radius: var(--rounded-corners);
          width: 564px;
          height: 564px;

          background-position: center;
          background-size: cover;
          @media (--tablet) {
            width: 512px;
            height: 512px;
          }
          @media (--tablet-xs-and-less) {
            flex-direction: column;
            width: 100%;
            height: 450px;
          }
          @media (--mobile) {
            height: calc(100vw - 30px);
            max-height: 450px;
            width: calc(100vw - 30px);
          }
        }
        &__content {
          flex: 1;
          @media (--tablet-xs-and-less) {
          width: 100%;
          }
        }

        &__text {
          padding: 3% 60px 2%;
          @media (--tablet-xs-and-less) {
            padding-left: 0;
            padding-right: 0;
          }
          @media (--mobile) {
            padding: 20px 0 5px;
          }
          &__button {
            max-width: 852px;
            @media (--tablet-sm-and-less) {
              max-width: 100%;
            }
            .design-lavpris &,
            .design-completvvs & {
              text-align: center;
              margin-left: auto;
              margin-right: auto;
              display: flex;
              justify-content: center;
            }
            @media (--mobile) {
              text-align: left;
              margin-left: 0;
              margin-right: 0;
              .design-lavpris & {
                text-align: center;
              }
            }

            .btn {
              margin: 0 0 15px 0;
              font-family: var(--button-font);
              &-lg {
                .design-lavpris & {
                  font-size: calc(var(--button-font-size)* 1.4);
                  padding-top: 10px;
                  padding-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
    li.productbox {
      border-color: transparent;
    }
  }
  &__focus-banner-link {
    display: flex;
    height: 100%;
    text-decoration: none;
    color: $gColorFont1;
  }
  &__focus-banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    grid-column: span 2;
  }
  &__campaign-mosaic {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: var(--color-brand-500);
    padding: 40px 0 60px;
    border: none;
    @media (--tablet-lg) {
      padding: 30px 0 50px 0;
    }
    @media (--tablet-sm) {
      padding: 20px 0 40px 0;
    }
    @media (--mobile) {
      padding: 15px 0px;
    }
    &__content {
      display: flex;
      flex-direction: row;
      width: inherit;
      justify-content: space-between;
      width: 100%;
      gap: 2%;
      padding-bottom: var(--spacing-300);
      @media (--mobile) {
        padding: 0 15px;
      }
    }
    &__logo {
      width: 18%;
      flex-shrink: 0;
      img {
        max-width: 200px;
        max-height: 90px;
      }
      @media (--new-tablet),
      (--mobile) {
        display: none;
      }
    }
    &__splash-text {
      text-transform: var(--text-uppercase-on-splash-notification);
      background-color: var(--countdown-color-textsplash);
      color: var(--color-brand-50);
      border-radius:var(--rounded-corners-text-splash);
      font-family: var(--brand-font);
      font-weight: var(--font-weight-on-splash-notification);
      padding: 4px 16px;
      font-size: 150%;
      @media (--mobile) {
        font-size: 110%;
        padding: 3px 12px;
      }
    }
    &__splash-svg {
      width: 18%;
      display: flex;
      justify-content: end;
      align-items: flex-start;
      flex-shrink: 0;
      svg {
        max-width: 300px;
        max-height: 300px;
      }
      @media (--new-tablet),
      (--mobile) {
        display: none;
      }
    }
    &__text-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 900px;
      color: var(--color-on-brand-color);
      gap: var(--spacing-300);
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    &__header {
      text-align: center;
      letter-spacing: var(--letter-spacing-sm);
      word-spacing: var(--word-spacing-sm);
      text-transform: var(--title-uppercase);
      color: var(--color-on-brand-color) !important;
      margin: 0;
      .design-completvvs & {
      &:after {
        border: var(--border-thin) var(--color-brand-700);
        content: "";
        width: 40%;
        display: block;
        margin: 15px auto 5px auto;
      }
    }
    }
    &__description {
      text-align: center;
      color: var(--countdown-color-text);
      margin-top: 0;
    }
    &__expiration-interaction {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
      &__expiration {
        font-size: 0.9rem;
        color: var(--countdown-color-disclaimer);
        @media (--mobile) {
          font-size: 0.8rem;
        }
      }
    }
    .product-card__content__header__h2 {
      color: var(--color-on-brand-color) !important;
      display: none;
    }
    .product-card__content__header__h3 {
      color: var(--color-primary-100) !important;
      display: none;
    }
    .productbox__slider__indicator {
      background-color: var(--color-primary-700);
    }
    .productbox__list__nav {
      border-color: var(--color-primary-700);
      svg {
        fill: var(--color-primary-700);
      }
      &:hover {
        background-color: var(--color-primary-700);
      }
    }
    .productbox__list__nav__wrapper .none-active {
      border-color: var(--color-primary-300);
      svg {
        fill: var(--color-primary-300);
      }
    }

    .new-productbox {
      --campaign-color: var(--countdown-color-textsplash);
      --color-on-campaign-color: var(--color-text-white);
    }

    .productbox__slider__indicator__wrapper:hover .productbox__slider__indicator, .productbox__slider__indicator__wrapper--active .productbox__slider__indicator {
      box-shadow: 0 0 0 10px rgba(0, 60, 90, 0.2);
    }
    .design-billigvvs &, .design-greenline &, .design-completvvs & {
      a.arrow-hover-target, .arrow-hover-target * {
      fill: var(--color-on-brand-color);
      color: var(--color-on-brand-color);
      border-color: var(--color-on-brand-color);
    }
  }
  }

  &__disclaimer-text {
    font-size: 0.9rem;
    text-align: center;
    color: var(--countdown-color-disclaimer);
    @media (--mobile) {
      font-size: 0.8rem;
    }
  }
}

@import "_product-stock";
@import "_productlist-footer";
@import "_productgrouplist";
