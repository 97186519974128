.explained-spec-one-column, .explained-spec-two-columns, .explained-spec-three-columns {
  h1, h2, h3, h4, h5, h6 {
  margin: 10px 0 0 0;
  }
  h1, h2 {
  font: var(--text-header-lg);
  }
  h3 {
  font: var(--text-header-md);
  }
  h4, h5 {
  font: var(--text-header-sm);
  }
  h5, h6 {
  font: var(--text-header-xs);
  }
  p {
  font-weight: var(--font-weight-regular);
  margin: 5px 0 15px;
  }
  ul, li {
  line-height: 2;
  }
  img {
  margin: 1% auto;
  width: fit-content;
  display: block;
  border-radius: var(--rounded-corners);
    @media (max-width: 767px) {
    width: 100%;
    border-radius: var(--rounded-corners-sm);
    }
  }
  .color-dark {
  color: var(--color-dark);
  }
  .color-grey-100 {
    color: var(--color-grey-100);
  }
  .color-grey-150 {
    color: var(--color-grey-100);
  }
  .color-grey-200 {
    color: var(--color-grey-150);
  }
  .color-brand {
  color: var(--brand-color);
  }
  .color-red {
  color: var(--color-red-500);
  }
  .color-green {
  color: var(--color-green-500);
  }
  .color-yellow {
  color: var(--color-yellow-500);
  }
  table {
  width: 100%;
  border-collapse: collapse;
  }
  tr {
  border-bottom: var(--border-thin) var(--border);
    @media (max-width: 399px) {
    display: grid;
    }
  &:nth-child(even) {
  background-color: var(--color-grey-50);
  }
  &:last-of-type {
  border: none;
  }
  }
  td, th {
  h2, h3 {
  margin: 0;
  }
  padding: var(--spacing-200) var(--spacing-300);
  width: 50%;
  flex-shrink: 0;
  &:first-of-type {
  font-weight: bold;
  }
    @media (max-width: 399px) {
    width: 100%;
    }
  }
  th {
  @media (max-width: 399px) {
  display: none;
  &:first-of-type {
  display: block;
  }
  }
  }
}

.explained-spec-two-columns,
.explained-spec-three-columns {
display: grid;
gap: 2%;
padding: 1% 0;
grid-template-columns: 1fr 1fr;
width: 100%;
  @media (max-width: 767px) {
  display: block;
  grid-template-columns: 100%;
}
}

.explained-spec-three-columns {
grid-template-columns: 1fr 1fr 1fr;
}